import type { LoaderFunction } from '@remix-run/node';
import { json, redirect } from '@remix-run/node';
import type { MetaFunction } from '@remix-run/react';
import {
  Link,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError,
} from '@remix-run/react';
import Layout from '~/components/layout/Layout';
import { fetchStrapi, fetchStrapiNoLimit } from '~/lib/server/api.server';
import type { BrandedPage as BrandedPageType } from '@cardo/types';
import { subAffiliateCookie } from '~/lib/server/cookies.server';
import { useSubAffiliate } from '~/hooks/useSubAffiliate';
import TopPicksPage from '~/components/routes/TopPicksPage';
import { getSingleCardPageTypePath } from '@cardo/lib';
import TopPicksCategoryPage from '~/components/routes/TopPicksCategoryPage';
import NotFoundErrorBoundary from '~/components/error/NotFoundErrorBoundary';
import FullPageBackgroundLayout from '~/components/layout/FullPageBackgroundLayout';
import {
  generateMetaFromSeoComponent,
  generateOgUrlMetaDescriptor,
} from '~/lib/meta';
import { generateAppUrl } from '~/lib/utils';

export function ErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.data === 'DraftStateError')
      return (
        <Layout>
          <div className="flex flex-col space-y-4">
            <h1>Still in Draft</h1>
            <p>Sorry, this page is still in progress.</p>
          </div>
        </Layout>
      );

    return <NotFoundErrorBoundary />;
  }

  return (
    <FullPageBackgroundLayout>
      <h1 className="mb-8 text-3xl">Sorry, something went wrong.</h1>
      <p>
        Try going <Link to="/">Home</Link>
      </p>
    </FullPageBackgroundLayout>
  );
}
export function headers({ loaderHeaders }: { loaderHeaders: Headers }) {
  return { 'cache-control': loaderHeaders.get('cache-control') };
}

export const loader: LoaderFunction = async ({ params }) => {
  const { slug } = params;
  const slugRes = await fetchStrapi<BrandedPageType[]>(
    '/branded-pages',
    {
      populate: 'influencer',
      filters: {
        slug,
      },
      publicationState: 'preview',
    },
    {}
  );

  if (!slugRes.data || slugRes.data.length === 0) {
    throw new Response('Not Found', { status: 404 });
  }

  // this is for the strapi preview button plugin to work
  // with multiple paths for the same collection type
  if (slugRes.data[0].attributes.pageType === 'single-card') {
    return redirect(getSingleCardPageTypePath(slugRes.data[0].attributes.slug));
  }

  const influencer = slugRes.data[0].attributes.influencer.data.id;
  const bankingPageEnabled =
    slugRes.data[0].attributes.influencer.data.attributes.bankingPageEnabled;
  const businessBankingPageEnabled =
    slugRes.data[0].attributes.influencer.data.attributes
      .businessBankingPageEnabled;
  const disabled =
    slugRes.data[0].attributes.influencer.data.attributes.disabled;

  if (disabled) {
    throw new Response('Not Found', { status: 404 });
  }

  const res = await fetchStrapiNoLimit<BrandedPageType[]>(
    '/branded-pages',
    {
      populate: {
        influencer: {
          populate: ['socialLinks', 'avatar'],
        },
        blocks: {
          populate: {
            creditCard: {
              populate: {
                blog: {
                  fields: ['slug'],
                },
                editorial: {
                  populate: { lines: '*' },
                },
                calculator: {
                  populate: '*',
                },
                cta: true,
              },
            },
            editorial: {
              populate: { lines: '*' },
            },
            creditCards: {
              populate: {
                creditCard: {
                  populate: {
                    blog: {
                      fields: ['slug'],
                    },
                    editorial: {
                      populate: { lines: '*' },
                    },
                    calculator: {
                      populate: '*',
                    },
                    cta: true,
                  },
                },
                editorial: {
                  populate: { lines: '*' },
                },
              },
            },
          },
        },
      },
      publicationState: 'preview',
      filters: {
        influencer,
      },
    },
    {}
  );

  if (!res.data || res.data.length === 0) {
    throw new Response('Not Found', { status: 404 });
  }

  const brandedPage = res.data.find((page) => page.attributes.slug === slug);

  if (brandedPage?.attributes.publishedAt === null) {
    throw new Response('DraftStateError', { status: 404 });
  }

  let headers = new Headers({
    'Cache-Control': 'max-age=1, stale-while-revalidate=31536000',
  });

  if (
    brandedPage &&
    brandedPage.attributes.influencer.data &&
    brandedPage.attributes.influencer.data.attributes.username
  ) {
    headers.append(
      'Set-Cookie',
      await subAffiliateCookie.serialize(
        brandedPage.attributes.influencer.data.attributes.username
      )
    );
  }

  return json(
    {
      brandedPage,
      brandedPages: res.data,
      bankingPageEnabled,
      businessBankingPageEnabled,
    },
    { headers }
  );
};

export const meta: MetaFunction = ({ data, location }) => {
  return [
    {
      title: data?.brandedPage?.attributes.pageTitle ?? 'Cardonomics',
    },
    {
      name: 'description',
      content: data?.brandedPage?.attributes.pageDescription,
    },
    {
      name: 'og:title',
      content: data?.brandedPage?.attributes.pageTitle,
    },
    {
      name: 'og:description',
      content: data?.brandedPage?.attributes.pageDescription,
    },
    {
      name: 'og:type',
      content: 'website',
    },
    ...generateMetaFromSeoComponent(data?.brandedPage?.attributes.seo),
    generateOgUrlMetaDescriptor(location),
    {
      tagName: 'link',
      rel: 'canonical',
      href: generateAppUrl(location.pathname),
    },
  ];
};

export default function BrandedTopPicksPage() {
  const {
    brandedPage,
    brandedPages,
    bankingPageEnabled,
    businessBankingPageEnabled,
  } = useLoaderData<typeof loader>() as {
    brandedPage: BrandedPageType;
    brandedPages: BrandedPageType[];
    bankingPageEnabled: boolean;
    businessBankingPageEnabled: boolean;
  };

  useSubAffiliate(brandedPage);

  if (brandedPage.attributes.pageType === 'top-picks')
    return (
      <TopPicksPage
        brandedPage={brandedPage}
        brandedPages={brandedPages}
        bankingPageEnabled={bankingPageEnabled}
        businessBankingPageEnabled={businessBankingPageEnabled}
      />
    );
  if (brandedPage.attributes.pageType === 'top-picks-category')
    return (
      <TopPicksCategoryPage
        brandedPage={brandedPage}
        brandedPages={brandedPages}
        bankingPageEnabled={bankingPageEnabled}
        businessBankingPageEnabled={businessBankingPageEnabled}
      />
    );

  return null;
}
